header{
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.headerContainer {
    text-align: center;
    height: 100%;
    position: relative;
}

/* Call To Action Buttons */

.CallToAction{
    margin-top: 1rem;
    display: flex;
    gap: 1.1rem;
    justify-content: center;
}

/* Social Media */

.headerSocials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.headerSocials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* profile picture */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 2rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 1rem 1.5rem 1.5rem 1.5rem;
}

/* Media Queries */

/* Medium devices */
@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
    .me{
        height: 55%;
    }
}

/* Small devices */

@media screen and (max-width: 600px) {
    header{
        height: 90vh;
    }

    .me{
        height: 60%;
    }

    .headerSocials{
        display: none;
    }

}